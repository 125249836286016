<template>
  <div>
    <b-row class="mx-0">
      <b-col sm="12">
        <b-button variant="primary"
                  size="sm"
                  @click="openEditOpinion(-1, {
                    footerShowAddress: false,
                    footerAddress1: '',
                    footerTitle: '',
                    footerAddress2: '',
                    footerAddress3: '',
                    footerLinks: [
                      {
                        name: '',
                        link: '',
                      },
                      {
                        name: '',
                        link: '',
                      },
                      {
                        name: '',
                        link: '',
                      },
                    ],
                    footerDescription: '',
                    footerCopyright: '',
                  })"
        >
          <sw-icon icon="PlusIcon" />
          {{ $t('Add') }}
        </b-button>
      </b-col>
    </b-row>

    <b-row class="mt-50">
      <b-col
        v-for="(item, index) in newWebsite.offerWebTemplateFooterItems"
        :key="index"
        sm="12"
        md="6"
      >
        <div class="d-flex justify-content-between align-items-center">
          <div>{{ index + 1 }}. {{ $t('Footer') }}</div>
          <div>
            <b-button
              variant="flat-warning"
              class="btn-icon ml-25"
              size="sm"
              @click="openEditOpinion(index, item)"
            >
              <feather-icon icon="EditIcon" />
            </b-button>
            <b-button
              variant="flat-danger"
              class="btn-icon ml-25"
              size="sm"
              @click="
                newWebsite.offerWebTemplateFooterItems.splice(index, 1)

                newWebsite.offerWebTemplateFooterItems.push()
              "
            >
              <feather-icon icon="TrashIcon" />
            </b-button>
          </div>
        </div>
        <footer-item
          :item="item"
        />
      </b-col>
    </b-row>

    <!--    Modal: Change opinion options     -->
    <b-modal
      v-model="showChangeOpinionOptionsModal"
      :title="$t('offer.website.Footer')"
      hide-footer
      no-close-on-backdrop
    >
      <b-form v-if="selectedOption.opt">
        <b-row class="mx-0">
          <!--    Option: Address    -->
          <b-col
            sm="12"
            class="px-1 mt-1"
          >
            <my-option
              :title="$t('Title')"
              my-class="flex-column"
            />

            <b-form-input
              id="popover-footerlabel-0"
              v-model.trim="selectedOption.opt.footerTitle"
              :placeholder="$t('Title')"
              max-length="50"
            />

            <!--    Popover    -->
            <b-popover
              target="popover-footerlabel-0"
              triggers="focus"
              variant="primary"
            >
              <span>{{ $t('MaximumLength') + ': 50' }}</span>
            </b-popover>
          </b-col>
          <b-col
            sm="12"
            class="px-1 mt-1"
          >
            <my-option
              :title="$t('contact.address')"
            />
            <b-row>
              <b-col sm="12">
                <b-form-group>
                  <b-form-input
                    id="popover-footerAddress1"
                    v-model.trim="selectedOption.opt.footerAddress1"
                    :placeholder="$t('lineOne')"
                    max-length="50"
                  />
                </b-form-group>

                <!--    Popover    -->
                <b-popover
                  target="popover-footerAddress1"
                  triggers="focus"
                  variant="primary"
                >
                  <span>{{ $t('MaximumLength') + ': 50' }}</span>
                </b-popover>
              </b-col>
              <b-col sm="12">
                <b-form-group>
                  <b-form-input
                    id="popover-footerAddress2"
                    v-model.trim="selectedOption.opt.footerAddress2"
                    :placeholder="$t('lineTwo')"
                    max-length="50"
                  />
                </b-form-group>

                <!--    Popover    -->
                <b-popover
                  target="popover-footerAddress2"
                  triggers="focus"
                  variant="primary"
                >
                  <span>{{ $t('MaximumLength') + ': 50' }}</span>
                </b-popover>
              </b-col>
              <b-col sm="12">
                <b-form-group>
                  <b-form-input
                    id="popover-footerAddress3"
                    v-model.trim="selectedOption.opt.footerAddress3"
                    :placeholder="$t('lineThree')"
                    max-length="50"
                  />
                </b-form-group>

                <!--    Popover    -->
                <b-popover
                  target="popover-footerAddress3"
                  triggers="focus"
                  variant="primary"
                >
                  <span>{{ $t('MaximumLength') + ': 50' }}</span>
                </b-popover>
              </b-col>
            </b-row>
          </b-col>

          <!--    Option: Links    -->
          <b-col
            sm="12"
            class="px-1 mt-1"
          >

            <my-option
              :title="$t('Links')"
              my-class="flex-column"
            />

            <b-row class="flex-grow-1 w-100">
              <b-col
                md="4"
              >
                <b-form-input
                  id="popover-footerName-0"
                  v-model.trim="selectedOption.opt.footerLinks[0].name"
                  :placeholder="$t('Name')"
                  max-length="50"
                />

                <!--    Popover    -->
                <b-popover
                  target="popover-footerName-0"
                  triggers="focus"
                  variant="primary"
                >
                  <span>{{ $t('MaximumLength') + ': 50' }}</span>
                </b-popover>
              </b-col>

              <b-col
                md="8"
              >
                <b-form-input
                  v-model.trim="selectedOption.opt.footerLinks[0].link"
                  :placeholder="$t('Link')"
                />
              </b-col>
            </b-row>

            <hr class="w-100">

            <b-row class="flex-grow-1 w-100">
              <b-col
                md="4"
              >
                <b-form-input
                  id="popover-footerName-1"
                  v-model.trim="selectedOption.opt.footerLinks[1].name"
                  :placeholder="$t('Name')"
                  max-length="50"
                />

                <!--    Popover    -->
                <b-popover
                  target="popover-footerName-1"
                  triggers="focus"
                  variant="primary"
                >
                  <span>{{ $t('MaximumLength') + ': 50' }}</span>
                </b-popover>
              </b-col>

              <b-col
                md="8"
              >
                <b-form-input
                  v-model.trim="selectedOption.opt.footerLinks[1].link"
                  :placeholder="$t('Link')"
                />
              </b-col>
            </b-row>

            <hr class="w-100">

            <b-row class="flex-grow-1 w-100">
              <b-col
                md="4"
              >
                <b-form-input
                  id="popover-footerName-2"
                  v-model.trim="selectedOption.opt.footerLinks[2].name"
                  :placeholder="$t('Name')"
                  max-length="50"
                />

                <!--    Popover    -->
                <b-popover
                  target="popover-footerName-2"
                  triggers="focus"
                  variant="primary"
                >
                  <span>{{ $t('MaximumLength') + ': 50' }}</span>
                </b-popover>
              </b-col>

              <b-col
                md="8"
              >
                <b-form-input
                  v-model.trim="selectedOption.opt.footerLinks[2].link"
                  :placeholder="$t('Link')"
                />
              </b-col>
            </b-row>
          </b-col>

          <!--    Option: Description    -->
          <b-col
            sm="12"
            class="px-1 mt-1"
          >
            <my-option
              :title="$t('Description')"
            />
            <b-form-group>
              <b-form-textarea
                id="popover-footerDesc"
                v-model.trim="selectedOption.opt.footerDescription"
                :placeholder="$t('Description')"
                max-length="170"
              />
            </b-form-group>
            <!--    Popover    -->
            <b-popover
              target="popover-footerDesc"
              triggers="focus"
              variant="primary"
              placement="top"
            >
              <span>{{ $t('MaximumLength') + ': 170' }}</span>
            </b-popover>
          </b-col>

        </b-row>
        <b-button
          variant="success"
          class="ml-25"
          size="sm"
          @click="update"
        >
          {{ $t('Save') }}
        </b-button>
      </b-form>
    </b-modal>
  </div>
</template>

<script>
import {
  BForm,
  BFormTextarea, BPopover, VBTooltip,
} from 'bootstrap-vue'
import myOption from '@/views/offers/website/Option.vue'
import Ripple from 'vue-ripple-directive'
import FooterItem from '@/views/offers/website/FooterItem.vue'

export default {
  components: {
    BForm,
    FooterItem,
    BFormTextarea,
    myOption,
    BPopover,
  },

  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },

  props: {
    value: {
      type: Object,
      required: true,
    },
  },

  data: () => ({
    newWebsite: { },
    selectedOption: { },

    newOption: {
      footerShowAddress: false,
      footerAddress1: '',
      footerTitle: '',
      footerAddress2: '',
      footerAddress3: '',
      footerLinks: [
        {
          name: '',
          link: '',
        },
        {
          name: '',
          link: '',
        },
        {
          name: '',
          link: '',
        },
      ],
      footerDescription: '',
      footerCopyright: '',
    },

    aboutNewOption: '',

    showChangeOpinionOptionsModal: false,
  }),

  mounted() {
    this.newWebsite = this.value
  },

  methods: {
    addOpinion() {
      this.newWebsite.offerWebTemplateFooterItems.push({
        footerAddress1: this.newOption.footerAddress1,
        footerAddress2: this.newOption.footerAddress2,
        footerAddress3: this.newOption.footerAddress3,
        footerLinks: this.newOption.footerLinks,
        footerTitle: this.newOption.footerTitle,
        footerDescription: this.newOption.footerDescription,
      })

      this.newOption.footerAddress1 = ''
      this.newOption.footerAddress2 = ''
      this.newOption.footerAddress3 = ''
      this.newOption.footerTitle = ''
      this.newOption.footerLinks = [
        {
          name: '',
          link: '',
        },
        {
          name: '',
          link: '',
        },
        {
          name: '',
          link: '',
        },
      ]
      this.newOption.footerDescription = ''
    },

    update() {
      // eslint-disable-next-line vue/no-mutating-props
      this.$emit('update-footer', { index: this.selectedOption.id, value: this.selectedOption.opt })

      this.showChangeOpinionOptionsModal = false
      this.$forceUpdate()
    },

    openEditOpinion(index, item) {
      this.selectedOption = {
        id: index,
        translationNr: 0,
        opt: JSON.parse(JSON.stringify(item)),
      }

      this.showChangeOpinionOptionsModal = true
    },
  },
}
</script>
